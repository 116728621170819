import moment from "moment";
import { Link } from "react-router-dom";
import { FiExternalLink } from "react-icons/fi";
import { Button, Tag } from "antd";

export const CHART_SLUGS = {
  STATISTICS: "statistics",
  PERFORMANCE_REVIEWS: "performance-reviews",
  MATERIAL_PROCUREMENT: "material-procurement",
  PERFECT_ORDERS: "perfect-orders",
  AVERAGE_INVENTORY: "average-inventory",
  STOCKS_OUT: "stocks-out",
  PHASE_COMPLETION: "phase-completion",
};

export const CHART_SCHEMAS = {
  STATISTICS: {
    title: "Statistics",
  },
  PERFORMANCE_REVIEWS: {
    title: "Performance Reviews",
    angleField: "value",
    colorField: "type",
  },
  MATERIAL_PROCUREMENT: {
    title: "Material Procurement Data (Lead Time)",
  },
  PERFECT_ORDERS: {
    title: "Perfect Orders",
    xField: "date",
    yField: "value",
  },
  AVERAGE_INVENTORY: {
    title: "Average Inventory",
    xField: "date",
    yField: "value",
  },
  STOCKS_OUT: {
    title: "Stocks Out",
    xField: "time",
    yField: "value",
    seriesField: "category",
  },
  PHASE_COMPLETION: {
    title: "Phase Completion",
    childrenSchema: [
      {
        title: "Milestone Statuses",
        angleField: "value",
        colorField: "type",
      },
      {
        title: "Task Statuses",
        angleField: "value",
        colorField: "type",
      },
    ],
  },
};

export const DATE_RANGES = [
  {
    value: "today",
    label: "Today",
  },
  {
    value: "week",
    label: "This Week",
  },
  {
    value: "month",
    label: "This Month",
  },
  {
    value: "quarter",
    label: "This Quarter",
  },
  {
    value: "year",
    label: "This Year",
  },
];

export const PROJECT_COLUMNS = [
  {
    title: "ID",
    dataIndex: "id",
  },
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Start Date",
    dataIndex: "start_date",
    render: (text) => <span>{moment(text).format("DD-MM-YYYY, HH:mm")}</span>,
  },
  {
    title: "End Date",
    dataIndex: "end_date",
    render: (text) => <span>{moment(text).format("DD-MM-YYYY, HH:mm")}</span>,
  },
  {
    title: "Project Type",
    dataIndex: "id",
    render: (_, { project_type }) => {
      return <span>{project_type?.data?.attributes?.name}</span>;
    },
  },
  {
    title: "Status",
    dataIndex: "status",
    selectable: true,
    render: (text) => <Tag>{text}</Tag>,
  },
  {
    title: "Details",
    dataIndex: "id",
    render: (text) => (
      <Link to={`/quick-projects/projects/edit/${text}`}>
        <Button icon={<FiExternalLink />}></Button>
      </Link>
    ),
  },
];

export const REPORTS = [
  {
    id: "agencies-report",
    name: "Agencies Report",
  },
  {
    id: "sub-contractors-report",
    name: "Sub-contractors Report",
  },
  {
    id: "vendors-report",
    name: "Vendors Report",
  },
  {
    id: "stock-ledgers-report",
    name: "Stock ledger Report",
  },
  {
    id: "hand-tools-report",
    name: "Hand tool Report",
  },
  {
    id: "machines-report",
    name: "Machine Report",
  },
  {
    id: "required-task-materials-report",
    name: "List of Materials",
  },
  {
    id: "vendor-material-receivables-report",
    name: "Cost Report Material",
  },
  {
    id: "sub-contractor-work-orders-report",
    name: "Sub Contractor Cost Report",
  },
  {
    id: "agency-work-orders-report",
    name: "Agency Cost Report",
  },
  {
    id: "released-notes-report",
    name: "Released Form Reports",
  },
  {
    id: "sub-contractors-ledger-report",
    name: "Sub Contractor Ledgers",
  },
  {
    id: "vendors-ledger-report",
    name: "Vendor Ledgers",
  },
  {
    id: "agencies-ledger-report",
    name: "Agency Ledgers",
  },
  {
    id: "consumables-report",
    name: "Consumables Report",
  },
  {
    id: "cashflow-tracker-report",
    name: "Cash Flow Tracker Report",
  },
  {
    id: "quotations-report",
    name: "Quotations Report",
  },
  {
    id: "created-invoices-report",
    name: "Invoices Report",
  },
  {
    id: "replace-material-report",
    name: "Replace Material Report",
  },
];
